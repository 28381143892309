<template>
  <main-card-header :title="routerTitle"></main-card-header>
  <div class="flex justify-center flex-wrap content-evenly items-center">
    <small-form
      :title="routerTitle"
      instructions="Enter the new practice name"
      :inputs="formItems"
      @resolve-form-submit="addPractice"
      :errorMessage="newPracticeError"
    ></small-form>
    <div>
      <div class="flex justify-center flex-wrap">
        <card-widget
          cardTitle="Practices"
          icon="home"
          :cardValue="getPracticesCards.total"
        ></card-widget>
        <card-widget></card-widget>
      </div>
      <div class="flex justify-center flex-wrap">
        <card-widget></card-widget>
        <card-widget></card-widget>
      </div>
    </div>
  </div>
  <div class="flex justify-center items-center content-evenly">
    <small-dropdown-form
      title="Assign Practice"
      instructions="Assign Practice to User"
      @resolve-form-submit="assignUserToPractice"
      :errorMessage="assignToUserError"
      :key="componentKey"
    >
      <label>Assign User:</label>
      <drop-down
        selectName="email"
        :values="getUsers"
        :onChange="resolveOnChange"
      />
      <label>To:</label>
      <drop-down
        selectName="practice"
        :values="getPractices"
        :onChange="resolveOnChange"
      />
    </small-dropdown-form>
    <small-dropdown-form
      title="Remove User/Practice"
      instructions="Remove user from practice"
      @resolve-form-submit="removeUserFromPractice"
      :errorMessage="removeUserFromPracticeError"
      :key="componentKey"
    >
      <label>Remove User:</label>
      <drop-down
        selectName="email"
        :values="getUsers"
        :onChange="resolveOnChange"
      />
      <label>From:</label>
      <drop-down
        selectName="practice"
        :values="getPractices"
        :onChange="resolveOnChange"
      />
    </small-dropdown-form>
  </div>
  <template v-if="!isLoading">
    <dynamic-table title="Practice" @delete="deletePractice"></dynamic-table>
  </template>
  <template v-else>
    <heart-rate fast="true"></heart-rate>
  </template>
  <my-pagination
    v-if="!!getPracticesCards.total"
    :options="options"
    v-model="page"
    :records="getPracticesCards.total"
    :per-page="getPracticesCards.per_page"
    @paginate="myCallback"
  >
  </my-pagination>
  <blank-paginate v-else />
</template>
<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import blankPaginate from "@/components/ui/baseComponents/blankPaginate.vue";
import dynamicTable from "@/components/layout/SA/table/table.vue";
import smallDropdownForm from "@/components/ui/forms/smallDropdownForm.vue";
import dropDown from "@/components/ui/forms/dropDown.vue";
import smallForm from "@/components/ui/forms/smallForm.vue";
import cardWidget from "@/components/ui/SA/cardWidget.vue";
export default {
  components: {
    dynamicTable,
    smallDropdownForm,
    dropDown,
    smallForm,
    cardWidget,
    blankPaginate,
  },
  data() {
    return {
      options: {
        template: markRaw(customPaginate),
      },
      page: 1,
      componentKey: 0,
      tableHeaders: ["NAME", "ID", "CREATED", "POLICIES", "ACTIONS"],
      formItems: [
        {
          type: "input",
          placeholder: "Practice Name",
          fieldName: "name",
        },
      ],
      practiceData: {},
      userData: {},
      localInputs: {},
      isLoading: false,
      newPracticeError: null,
      assignToUserError: null,
      removeUserFromPracticeError: null,
    };
  },
  provide() {
    return { headers: this.tableHeaders };
  },
  created() {
    this.loadPractices(this.page);
    this.loadUsers();
  },
  methods: {
    myCallback(e) {
      this.loadPractices(e);
    },
    resolveOnChange(data) {
      this.localInputs[data.target.name] = data.target.value;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    async loadPractices(page) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("admin/loadPractices", page);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async loadUsers() {
      // this.isLoading = true;
      try {
        await this.$store.dispatch("admin/loadUsers");
        // this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async deletePractice(id) {
      try {
        await this.$store.dispatch("admin/deletePractice", id);
        this.isLoading = false;
        this.$toast.error(`Practice Deleted!`);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },

    async addPractice(data) {
      try {
        await this.$store.dispatch("admin/addPractice", data);
        this.newPracticeError = null;
        this.isLoading = false;
        this.$toast.success(`Practice Created!`);
      } catch (error) {
        this.error = error.message;
        this.newPracticeError = error.message;
      }
    },
    async assignUserToPractice() {
      try {
        await this.$store.dispatch(
          "admin/assignUserToPractice",
          this.localInputs
        );
        this.assignToUserError = null;
        this.isLoading = false;
        this.forceRerender();
        this.$toast.success(`User successfully assigned!`);
      } catch (error) {
        this.error = error.message;
        this.assignToUserError = error.message;
      }
    },
    async removeUserFromPractice() {
      try {
        await this.$store.dispatch(
          "admin/removeUserFromPractice",
          this.localInputs
        );
        this.removeUserFromPracticeError = null;
        this.isLoading = false;
        this.forceRerender();
        this.$toast.success(`User successfully removed!`);
      } catch (error) {
        this.error = error.message;
        this.removeUserFromPracticeError = error.message;
      }
    },
  },
  computed: {
    routerTitle() {
      return this.$route.meta.title;
    },
    getPracticesCards() {
      return this.$store.getters["admin/getPractices"];
    },
    getPractices() {
      const data = this.$store.getters["admin/getPractices"];
      if (Object.keys(data).length !== 0) {
        data.data.forEach((item) => {
          this.practiceData[item.id] = item.practice_name;
        });
        return this.practiceData;
      } else {
        return this.practiceData;
      }
    },
    getUsers() {
      const data = this.$store.getters["admin/getUsers"];
      if (Object.keys(data).length !== 0) {
        data.data.forEach((item) => {
          this.userData[item.email] = item.email;
        });
        return this.userData;
      } else {
        return this.userData;
      }
    },
  },
};
</script>
